
import Badge from '@/components/status/Badge.vue'
import BusinessBranchFilter from 'tradingmate-modules/src/filters/BusinessBranchFilter'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
// import BusinessBranchCard from 'tradingmate-modules/src/models/api/businesses/BusinessBranchCard'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
// import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'
import { SearchBar, SearchBarItem } from '@/components/search'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import Paging from '@/components/actions/Paging.vue'
import { CategorySelector, InputSelect, InputText, ZoneSelector } from '@/components/inputs'
import BusinessBranchSearchResponse
  from '../../../../../../tradingmate-modules/src/models/api/businesses/BusinessBranchSearchResponse'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    SearchBar,
    SearchBarItem,
    ZoneSelector,
    CategorySelector,
    Paging,
    Badge,
    InputText,
    InputSelect
  }
})

export default class AdminBusinesses extends Vue {
  private businesses: BusinessBranchSearchResponse | null = null
  private filter: BusinessBranchFilter = new BusinessBranchFilter({
    OrderByRecent: true,
    Statuses: [
      BusinessBranchStatus.None,
      BusinessBranchStatus.Deleted,
      BusinessBranchStatus.Inactive,
      BusinessBranchStatus.Active
    ]
  })

  private loading = true;
  private isExporting = false;

  mounted (): void {
    this.updateFilter()
    this.GetBusinesses()
  }

  GetBusinessCategories (categories: CategoryModel[]): string {
    return categories.map((cat) => { return cat.Name }).join(', ')
  }

  GetZones (zones: ZoneModel[] | null): string {
    if (zones == null || !zones.length) return 'N/A'
    return zones.map((zone) => { return zone.TMName }).join(', ')
  }

  private searchQuery = ''
  zones: ZoneModel[] = []
  categories: CategoryModel[] = []
  private selectedStatus: BusinessBranchStatus = BusinessBranchStatus.None

  statuses: DropdownListItem[] = [
    { Key: BusinessBranchStatus[0], Label: 'All', Value: [BusinessBranchStatus.None] },
    { Key: BusinessBranchStatus[1], Label: 'Deleted', Value: [BusinessBranchStatus.Deleted] },
    { Key: BusinessBranchStatus[2], Label: 'Inactive', Value: [BusinessBranchStatus.Inactive] },
    { Key: BusinessBranchStatus[3], Label: 'Active', Value: [BusinessBranchStatus.Active] },
    { Key: BusinessBranchStatus[4], Label: 'Draft', Value: [BusinessBranchStatus.Draft] }
  ]

  search (): void {
    this.updateQuery()
    this.updateFilter()
    this.GetBusinesses()
  }

  updateQuery (): void {
    const zoneIds = this.zones.map((item) => { return item.ZoneId })
    const categoryIds = this.categories.map((item) => { return item.CategoryId })
    this.$router.push({
      query: {
        categories: categoryIds ?? undefined,
        zones: zoneIds ?? undefined,
        search: this.searchQuery.length ? this.searchQuery : undefined,
        status: this.selectedStatus.toString() ?? undefined,
        userId: this.filter.UserId ?? undefined
      }
    })
  }

  updateFilter (): void {
    this.filter.CategoryIds = this.$route.query.categories?.toString().split(',')
    this.filter.ZoneIds = this.$route.query.zones?.toString().split(',')
    this.filter.Search = this.$route.query.search?.toString() ?? undefined
    const stringArray = this.$route.query.status?.toString().split(',')
    const intArray = stringArray?.map((item) => { return parseInt(item) })
    this.filter.Statuses = intArray ?? undefined
    this.filter.UserId = this.$route.query.userId?.toString()
  }

  GetBusinesses (): void {
    this.loading = true
    Services.API.Businesses.SearchBranches(this.filter)
      .then((returnData) => { this.businesses = returnData })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => { this.loading = false })
  }

  handlePaged (page: number): void {
    this.filter.Page = page
    this.search()
  }

  handleExportClick (): void {
    this.updateFilter()
    this.isExporting = true
    Services.API.Admin.Businesses.ExportBusinesses(this.filter)
      .then((response) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(response)
        link.download = 'businesses.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      }).finally(() => {
        this.isExporting = false
      })
  }

  editWebsite (id: string): void {
    this.$router.push('/my-websites/edit/' + id)
  }

  editProfile (id: string): void {
    this.$router.push({ name: 'Business Branch Edit', params: { id: id } })
  }

  viewUsers (id: string): void {
    this.$router.push({ name: 'Admin Database Users', query: { branchId: id } })
  }
}

